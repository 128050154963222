module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"phone-repair-tokyo","short_name":"tokyo-repair","start_url":"/","background_color":"#0043ce","theme_color":" #0043ce","display":"minimal-ui","icon":"src/images/tablet.svg","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"1b6655446324ade21cd318b409ba6981"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
